import { keyframes } from '@mui/material';

import Icon, { IconProps } from '@/components/Icon';
import { ConversationMessageWrapperMessage } from '@/services/conversation-messages/managers/conversation-message-wrapper';
import { exhaustiveGuard } from '@/utils/ts-utils';
import { useEnableInboxUIUXRevamp } from '@/pages/InboxRXJS/hooks/useEnableInboxUIUXRevamp';

const COMMON_CSS = {
  flexShrink: 0,
  color: (theme: any) => theme.palette.componentToken?.window?.caption,
};

const rotate = keyframes`
0% {transform: rotate(0deg)}
100% {transform: rotate(360deg)}
`;

export default function MessageStatusIcon({
  status,
  ...rest
}: Pick<ConversationMessageWrapperMessage, 'status'> &
  Pick<IconProps, 'size'>) {
  const enableInboxUIUXRevamp = useEnableInboxUIUXRevamp();

  switch (status) {
    case 'Queued':
    case 'Sending':
      return (
        <Icon
          icon={enableInboxUIUXRevamp ? 'loading1' : 'loading'}
          sx={{
            animationName: `${rotate}`,
            animationDuration: '1s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
            ...COMMON_CSS,
            ...(enableInboxUIUXRevamp && { color: 'darkBlue.50' }),
          }}
          {...rest}
        />
      );
    case 'Received':
      return <Icon icon="check-double" sx={COMMON_CSS} {...rest} />;
    case 'Read':
      return (
        <Icon
          icon="check-double"
          sx={{ ...COMMON_CSS, color: 'blue.70' }}
          {...rest}
        />
      );
    case 'Sent':
      return <Icon icon="check-single" sx={COMMON_CSS} {...rest} />;
    case 'Scheduled': {
      return <Icon icon="clock-rewind" sx={COMMON_CSS} {...rest} />;
    }
    case 'Deleted': {
      return null;
    }
    case 'Undelivered':
    case 'OutOfCredit':
    case 'Failed':
      return (
        <Icon
          icon="alert-circle"
          sx={{
            ...COMMON_CSS,
            color: 'red.90',
          }}
          {...rest}
        />
      );
    default:
      return exhaustiveGuard(
        status,
        `${status} is an impossible message status`,
      );
  }
}
