import { useCompany } from '@/api/company';
import { Company } from '@/api/types';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';

export const INBOX_UIUX_REVAMP_COMPANIES =
  (import.meta.env.VITE_INBOX_UIUX_REVAMP_COMPANIES?.split(',') || []) as (
    | string
    | undefined
  )[];

const EnableInboxUIUXRevampContext = createContext(false);

export const EnableInboxUIUXRevampProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { data: companyId } = useCompany({
    select: useCallback((company: Company) => company.id, []),
  });
  const enable = useMemo(
    () => INBOX_UIUX_REVAMP_COMPANIES.includes(companyId),
    [companyId],
  );

  return (
    <EnableInboxUIUXRevampContext.Provider value={enable}>
      {children}
    </EnableInboxUIUXRevampContext.Provider>
  );
};

export const useEnableInboxUIUXRevamp = () => {
  return useContext(EnableInboxUIUXRevampContext);
};
