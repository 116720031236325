import { useGetStaffOverview } from '@/api/company';
import { StaffCore } from '@/services/companies/types';
import { createContext, ReactNode, useCallback, useContext } from 'react';

type StaffMap = Record<string, StaffCore>;
const StaffMapContext = createContext<StaffMap>({});

interface StaffMapProviderProps {
  children: ReactNode;
}

const EMPTY_STAFF_MAP: StaffMap = {};

export const StaffMapProvider = ({ children }: StaffMapProviderProps) => {
  const { data: allStaffs } = useGetStaffOverview({
    select: useCallback(
      (staffs: StaffCore[]) =>
        staffs.reduce((acc, staff) => {
          acc[staff.staffIdentityId] = staff;
          return acc;
        }, EMPTY_STAFF_MAP),
      [],
    ),
  });

  return (
    <StaffMapContext.Provider value={allStaffs || EMPTY_STAFF_MAP}>
      {children}
    </StaffMapContext.Provider>
  );
};

export const useStaffMapContext = () => {
  const staffMap = useContext(StaffMapContext);
  if (!staffMap) {
    throw new Error(
      'useStaffMapContext must be used within a StaffMapProvider',
    );
  }

  return staffMap;
};
